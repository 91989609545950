export const SEND_APPLINK_WHATSAPP_ENDPOINT = '/api/app/sendAppLink/'
export const SCANNER_IMAGE_ALT = 'Download app qr'
export const FALLBACK_PLACEHOLDER = 'Enter phone number'
export const INPUT_ERROR_MESSAGE = 'Enter a valid phone number'
export const FALLBACK_CTA_TEXT = 'Get the app link'
export const FALLBACK_HEADING = 'Enter your mobile number to get the download link'
export const FALLBACK_SUBHEADING = 'Scan the QR code or get the app download link on your mobile number'
export const FALLBACK_MAIN_CTATEXT = 'Download the app'
export const PHONE_NUMBER_REGEX = /^[6-9][0-9]{9}$/
export const COUPONS_LIST_COMPONENT = 'coupons_list'
