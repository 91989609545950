'use client'

import { getCookie } from 'cookies-next'
import isMobile from 'ismobilejs'

export function webengageAnonymousID() {
  let aid = getCookie('we_aid')
  if (aid !== undefined) return aid
  try {
    aid = window.webengage.user.getAnonymousId()
  } catch {
    aid = ''
  }
  return aid
}

export function getGAClientID() {
  try {
    const gaCookieArr = getCookie('_ga')?.split('.')
    if (gaCookieArr && gaCookieArr?.length > 1) {
      return `${gaCookieArr?.[gaCookieArr.length - 2]}.${gaCookieArr?.[gaCookieArr.length - 1]}`
    }
    return ''
  } catch {
    return ''
  }
}

export const getR2d2CommonProperties = () => ({
  user: getCookie('user_id'),
  tracker_id: getCookie('trackerid'),
  oid: getCookie('trackerid'),
  edata: {
    anonymousID: webengageAnonymousID(),
    ga_client_id: getGAClientID(),
  },
})

export const getR2d2Payload = ({ isSemPage = false, ekind, edata = {}, odata = {}, r2d2CommonProperties = {} }) => {
  const updatedR2d2CommonProps =
    Object.keys(r2d2CommonProperties).length > 0 ? r2d2CommonProperties : getR2d2CommonProperties()
  const isMobileDevice = window !== undefined && isMobile(window.navigator.userAgent).any
  const updatedEdata = {
    ...(updatedR2d2CommonProps.edata || {}),
    ...(edata || {}),
    is_js: true,
    platform: !isMobileDevice ? 'web' : getCookie('webview') ? 'app' : 'mweb',
    web_engage: true,
  }

  const updatedOdata = {
    ...(odata || {}),
    url: window?.location?.href,
  }

  return {
    ...updatedR2d2CommonProps,
    app: isSemPage ? 'NS_React' : 'NS_Container',
    user_agent: window?.navigator?.userAgent,
    ekind,
    okind: 'tracker',
    edata: JSON.stringify(updatedEdata),
    odata: JSON.stringify(updatedOdata),
  }
}

export const refreshR2d2Cookies = (segmentProperites = {}) => {
  const edata = JSON.parse(segmentProperites.edata || '{}')
  edata.ga_client_id = getGAClientID()
  edata.anonymousID = webengageAnonymousID()
  return {
    ...segmentProperites,
    user: getCookie('user_id'),
    tracker_id: getCookie('trackerid'),
    oid: getCookie('trackerid'),
    edata: JSON.stringify(edata),
  }
}
