export const growthBookConfig = {
  master: {
    host: 'https://growthbook-api.internal.ackodev.com',
    key: 'sdk-5ykpwUCKrkvlTZVW',
  },
  dev: {
    host: 'https://growthbook-api.internal.ackodev.com',
    key: 'sdk-5ykpwUCKrkvlTZVW',
  },
  local: {
    host: 'https://growthbook-api.internal.ackodev.com',
    key: 'sdk-5ykpwUCKrkvlTZVW',
  },
  prod: {
    host: 'https://ab.acko.com/',
    key: 'sdk-IE5Cm1Zwo1xyEFr',
  },
  'pre-prod': {
    host: 'https://ab.acko.com/',
    key: 'sdk-IE5Cm1Zwo1xyEFr',
  },
}
