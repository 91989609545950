export const ITEM_TYPE = {
  ARTICLES: 'Interesting Articles',
  NEWS: 'Top stories and News',
  REVIEWS: 'Car & Bike reviews',
  ARTICLESLISTING: 'Articles',
}

export const ITEM_ID = {
  ARTICLES: 'articles',
  NEWS: 'news',
  REVIEWS: 'reviews',
}

export const ACKODRIVE_CONTENT_TYPE_API = {
  ACKODRIVE_BLOG: 'blog-ads',
  ACKODRIVE_TOPICS: 'topic-ads',
  ACKODRIVE_NEWS: 'newss',
  ACKODRIVE_REVIEWS: 'reviewss',
}

export const ACKODRIVE_CONSOLE_LOG = {
  newss: 'getNewsData',
  reviewss: 'getReviewsData',
  'blog-ads': 'getBlogsData',
  'topic-ads': 'getTopicsData',
}

export const ACKODRIVE_PAGINATION_LIMIT = 10
export const FETCH_TIMEOUT = 1000
export const DEFAULT_PAGINATION_VALUE = 1
export const TEAM_ACKO = 'Team Acko'
export const TEAM_ACKODRIVE = 'Team Ackodrive'

export const TENANT_TYPE = {
  ACKODRIVE: 'ackoDrive',
  HOMEPAGE: 'homePage',
  ACKOSEO: 'ackoSeo',
  ACKOBRAND: 'ackoBrand',
  ACKOCAREER: 'ackoCareer'
}

export const ACKODRIVE_SCHEMA_TYPE = {
  CORPORATION_SCHEMA: 'corporation-schema',
  BREADCRUMB_SCHEMA: 'breadcrumb-schema',
  ARTICLE_SCHEMA: 'article-schema',
  FAQ_SCHEMA: 'faq-schema',
  VIDEO_SCHEMA: 'video-schema',
}

export const ACKO_AUTHOR_TYPE = 'authors'
export const ACKODRIVE_AUTHOR_TYPE = 'author-ads'
export const DEFAULT_ACTIVE_HEADER_TAB = 'BLOG'
export const ACKODRIVE_GTM_ID = 'GTM-KP3FN3Z'
export const ACKO_GTM_ID = 'GTM-TZVHFQH'
export const APPLICATION_LD_TYPE = 'application/ld+json'
export const NEWS_SHARE_WIDGET_URL =
  'https://news.google.com/publications/CAAqBwgKMM6Kqgww4Iq3BA?hl=en-IN&gl=IN&ceid=IN%3Aen'

export const TWITTER_ACKODRIVE_LOGO = 'Twitter AckoDrive Logo'
export const ACKODRIVE_DOMAIN = 'https://ackodrive.com/'

export const META_TITLE_LISTING_PAGE = {
  ARTICLES: 'Car Guide: Articles on Car advice, Maintenance Tips & More',
  NEWS: 'Car News, New Car Launches, Latest Auto News On ACKO Drive',
  REVIEWS: 'Expert Reviews on Cars & Bikes @ ACKO Drive',
}

export const META_DESCRIPTION_LISTING_PAGE = {
  ARTICLES: 'Explore Car Guide by ACKO Drive which talks all things cars. Read on to learn about Car Buying Guide, Maintenance & Driving Tips. Subscribe to get regular updates.',
  NEWS: 'Read the latest car news from the Indian automobile industry. Follow ACKO Drive for all the latest car and bike news!',
  REVIEWS: 'Read our expert car and bike reviews to make an informed decision on your car or bike purchase. ACKO Drive brings you first-drive reviews, car comparisons & road test reviews.',
}
