export const brandConfig = {
  prod: {
    ackoByteBaseUrl: 'https://central-carbon-prod.live.acko.com',
    videoChannelId: '2',
  },
  dev: {
    ackoByteBaseUrl: 'https://carbon.ackodev.com',
    videoChannelId: '9',
  },
  master: {
    ackoByteBaseUrl: 'https://carbon.ackodev.com',
    videoChannelId: '9',
  },
  local: {
    ackoByteBaseUrl: 'http://localhost:3000',
    videoChannelId: '9',
  },
}
