export const loginConfig = {
  local: {
    imsRealm: 'acko-uat',
  },
  master: {
    imsRealm: 'acko-uat',
  },
  dev: {
    imsRealm: 'acko-uat',
  },
  prod: {
    imsRealm: 'acko',
  },
  'pre-prod': {
    imsRealm: 'acko',
  },
}
