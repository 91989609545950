export const rewardClientNames = {
  local: {
    health: 'rewards_uat__acko__health',
    car: 'rewards_uat__acko__auto',
    bike: 'rewards_uat__acko__auto',
    life: 'rewards_uat__acko__life',
    travel: 'rewards_uat__acko__travel',
    ackoDrive: 'rewards_uat__acko__ackodrive',
    ackoDriveService: 'rewards_uat__acko__ackodriveservice',
  },
  master: {
    health: 'rewards_uat__acko__health',
    car: 'rewards_uat__acko__auto',
    bike: 'rewards_uat__acko__auto',
    life: 'rewards_uat__acko__life',
    travel: 'rewards_uat__acko__travel',
    ackoDrive: 'rewards_uat__acko__ackodrive',
    ackoDriveService: 'rewards_uat__acko__ackodriveservice',
  },
  dev: {
    health: 'rewards_uat__acko__health',
    car: 'rewards_uat__acko__auto',
    bike: 'rewards_uat__acko__auto',
    life: 'rewards_uat__acko__life',
    travel: 'rewards_uat__acko__travel',
    ackoDrive: 'rewards_uat__acko__ackodrive',
    ackoDriveService: 'rewards_uat__acko__ackodriveservice',
  },
  prod: {
    health: 'rewards_prod__acko__health',
    car: 'rewards_prod__acko__auto',
    bike: 'rewards_prod__acko__auto',
    life: 'rewards_prod__acko__life',
    travel: 'rewards_prod__acko__travel',
    ackoDrive: 'rewards_prod__acko__ackodrive',
    ackoDriveService: 'rewards_prod__acko__ackodriveservice',
  },
  'pre-prod': {
    health: 'rewards_prod__acko__health',
    car: 'rewards_prod__acko__auto',
    bike: 'rewards_prod__acko__auto',
    life: 'rewards_prod__acko__life',
    travel: 'rewards_prod__acko__travel',
    ackoDrive: 'rewards_prod__acko__ackodrive',
    ackoDriveService: 'rewards_prod__acko__ackodriveservice',
  },
}
